import React, { useState, useEffect } from 'react'
import './Work.scss';
import { urlFor, client } from '../../client'
import { AiFillEye, AiFillGithub } from 'react-icons/ai'
import { motion } from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper';
 
import { useAppContext } from '../../context/AppContext';

function Work() {
  const context= useAppContext();

  const [activeFilter, setactiveFilter] = useState('All')
  const [animateCard, setanimateCard] = useState({ y: 0, opacity: 1 })
  const [works, setWorks] = useState([]);
  const [FilterWork, setFilterWork] = useState([]);
  
  useEffect(() => {
    const query = '*[_type == "works"]';
    client.fetch(query)
      .then((data) => {
        setWorks(data);
        setFilterWork(data);

      })
  }, [])


  const handleWorkFilter = (item) => {
    setactiveFilter(item)
    setanimateCard([{y:100,opacity:0} ])

    setTimeout(() => {
      setanimateCard([{y:0,opacity:1}])
      if (item === 'All') {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };
  return (
    <>
      <h2 className="head-text">
        My Creative <span>Portfolio </span>Section <span> </span>
      </h2>

      <div className={context.theme=="light"?"app__work-filter": "app__work-filter dark"} >
        {['UI/UX' , 'Desktop App', 'Web App','Mobile App', 'All', 'React JS','Bot','Design','C App'].map((item, index) => (
          <div key={index}
            onClick={() => handleWorkFilter(item)}
            // className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
            className={context.theme=="light"?`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}` : `app__work-filter-item dark app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}

          </div>

        ))}

      </div>

      
      <motion.div
       
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {FilterWork.map((work, index) => (
         <div className="app__work-item app__flex" key={index}>
         <div
           className="app__work-img app__flex"
         >
       <img src={urlFor(work.imgUrl)} alt={work.name} />  
           {/* <img src='https://res.cloudinary.com/dorrhcigy/image/upload/v1666731682/Me/file%20fix%20size/Artboard_1_vog6qc.png' alt={work.name} /> */}

           
           <motion.div
           
             whileHover={{ opacity: [0, 1] }}
             transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
             className="app__work-hover app__flex"
           >
             <a href={work.projectLink} target="_blank" rel="noreferrer">

               <motion.div
                 whileInView={{ scale: [0, 1] }}
                 whileHover={{ scale: [1, 0.90] }}
                 transition={{ duration: 0.25 }}
                 className="app__flex"
               >
                 <AiFillEye />
               </motion.div>
             </a>
             <a href={work.codeLink} target="_blank" rel="noreferrer">
               <motion.div
                 whileInView={{ scale: [0, 1] }}
                 whileHover={{ scale: [1, 0.90] }}
                 transition={{ duration: 0.25 }}
                 className="app__flex"
               >
                 <AiFillGithub />
          </motion.div>
          </a>
           </motion.div>
           </div>

<div className="app__work-content app__flex">
  <h4 className='blod-text'>{work.title}</h4>
  <p className="p-text" style={{ marginTop: 10 }}>{work.description}</p>
  <div className="app__work-tag app__flex">
  <p className='p-text'>{work.tags[0]}</p>
</div>

</div>

          </div>
        ))}
      </motion.div>

    </>
  )
}

// export default AppWrap(
//   MotionWrap(Work, 'app__works'),
//   'work',
//   'app__primarybg',
// );


 export default AppWrap(MotionWrap(Work, 'app__works'),'work','app__primarybg','app__workdark',
);