import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './About.scss';
import '../../components/Navbar/Navbutton.scss'

import { MotionWrap,AppWrap } from '../../wrapper'
import ReactTooltip from 'react-tooltip';
import '../Skills/Skills.scss';
import { images } from '../../constants'

import { urlFor, client } from '../../client'
import { useAppContext } from '../../context/AppContext';
import Download from './Download';

function About() {
  
  
  const context= useAppContext();
  const [abouts, setAbouts] = useState([]);



  



  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
     
  }, []);

  const [educations, setEducations] = useState([]);
 // const [skills, setSkills] = useState([]);
  const [aboutsme, setAboutsme] = useState([]);

 
  useEffect(() => {
      const Eduquery = '*[_type == "educations"]';
    // const skillsQuery = '*[_type == "skills"]';
     const queryme = '*[_type == "aboutsme"]';


    client.fetch(Eduquery).then((data) => {
      setEducations(data);
    });

    // client.fetch(skillsQuery).then((data) => {
    //   setSkills(data);
    // });
    client.fetch(queryme).then((data) => {
      setAboutsme(data);
    });
  }, []);

  return (
    <div className={context.theme=="light"?"app__about": "app__about dark"}>
      
       <h2 className="head-text">
        I know that <span>Good Services </span>means <span>Good buissnes</span>
      </h2>
    
      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
          initial={{ y:100,opacity:0}}
             whileInView={{y:0,opacity:1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
        ))}

      </div>
      
      {/* <h3 className="head-text-welcome">Greetings , I’m Aymen Ghnia 🚀   I’m living in Monastir, Tunisia 🇹🇳 .<br></br> One of my <span>goals </span> to became <span>richer</span> than Elon musk 🍿<br></br>i love UI , Web development and for sure PIZZA .</h3> */}



      <h2 className="head-text" style={{ marginTop: 30 ,marginBottom: 20 }}>
       <span>Education 🎓 </span>
      </h2>
      <div className="app__skills-container">

{/*  Education section */}
     
      <motion.div className='app__skills-exp'>
          {educations.map((education) => (
            <motion.div
              className="app__skills-exp-item"
              key={education.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{education.year}</p>
              </div>
             
              <motion.div className="app__skills-exp-works">
                {education.works.map((work => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <h4 className='bold-text'>🎓 {work.name}</h4>
                      <p className='p-text'>{work.company}</p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                      effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </>
                )))}

              </motion.div>
            
            </motion.div>




          ))}

      </motion.div>

        {/*  me section */}
        
        <div className="app__profiles">
         

        {aboutsme.map((me) => (
          <motion.div
          initial={{ y:100,opacity:0}}
             whileInView={{y:0,opacity:1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={me.title} >
                      {/*  <div className='tag-cmpme' style={{ marginRight: 22 , marginTop: -110 ,height:"1px", width:"1 px"}}>
          <img  src={images.meagain} alt="profile_bg"/>
          </div>  */}

            {/* <img   src={urlFor(me.imgUrl)} alt={me.title} /> */}
            <img   src={images.aboutaymen} alt={me.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{me.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{me.description}</p>


            {context.theme == "light" ? (
               
  <div class="button-container-2">  
  
  
  {/* <a href={images.cv} download="AymenGhnia_Cv_En.pdf">
    <span class="mas">DOWNLOAD CV</span>
  <button type="button" name="Hover">DOWNLOAD CV</button>
  </a>   */}
 <a href="https://link.aymenghnia.dev/cv_en" download="fr_cv_aymen_ghnia.pdf">
    <span class="mas">DOWNLOAD CV</span>
  <button type="button" name="Hover">DOWNLOAD CV</button>
  </a>  

  </div>  
) : (


  <a style={{ marginRight: 40, marginLeft: 1 }} className='cv' href="https://link.aymenghnia.dev/cv_en" download="en_cv_aymen_ghnia.pdf">

  
  <button class="button-38-nav"  >Download CV  
    </button> </a> 
  
)
} 




            
              {/*  <div style={{ marginTop: 30 ,marginBottom: 20 }} ><a className='cv' href={images.cv} download="AymenGhnia_Cv_Fr.pdf">     
             <button class="button-38" role="button">Download CV</button> </a></div>

// <div class="button-container-2" style={{ marginTop: 30 ,marginBottom: 20 }} ><a className='cv' href={images.cv} download="AymenGhnia_Cv_Fr.pdf">     
// <span class="mas">DOWNLOAD CV</span> 
//               <button id='work' type="button" name="Hover">DOWNLOAD CV</button> </a></div>  */}

{/* <Download/> */}
{/* {context.theme == "light" ? ( */}

{/* // <div class="button-container-2"> */}
  
  
  
  {/* <a href={images.cv} download="AymenGhnia_Cv_Fr.pdf">
    <span class="mas">DOWNLOAD CV</span>
  <button type="button" name="Hover">DOWNLOAD CV</button>
  </a> */}
{/* <a href="https://link.aymenghnia.com/cvEn" download="AymenGhnia_Cv_Fr.pdf">
    <span class="mas">DOWNLOAD CV</span>
  <button type="button" name="Hover">DOWNLOAD CV</button>
  </a> */}


{/* // </div> */}
{/* // ) : ( */}

  {/* // <a style={{ marginRight: 40, marginLeft: 1 }} className='cv' href={images.cv} download="AymenGhnia_Cv_Fr.pdf"> */}
{/* <a style={{ marginRight: 40, marginLeft: 1 }} className='cv' href="https://link.aymenghnia.com/cvEn" download="AymenGhnia_Cv_Fr.pdf">

   */}
  {/* // <button class="button-38-nav"  >Download CV */}
  {/* //   </button> </a>  */}
{/* // )} */}



          </motion.div>
            
        ))}

      </div>
        </div>

    </div>
  )
}

 
// export default AppWrap(
//   MotionWrap(About, 'app__about'),'about','app__about',  'app__aboutdark',
//   // {context.theme =="light"?'app__whitebg' : 'app__blackbg'},

  
// );

export default AppWrap(
  MotionWrap(About, 'app__about'),'about','app__whitebg','app__aboutdark',
);
 
 

   
