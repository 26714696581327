import React,{useContext, createContext,useEffect,useState,useRef} from 'react'


export const StateContext= createContext();
export function useAppContext(){
   
   return  useContext(StateContext);
}

function AppContext({children}){

  
   
 const [theme,setTheme]=useState(localStorage.getItem("theme") || "light");
// const [theme, setTheme] = useState(() => {
//     if (localStorage.getItem('theme') === 'light') {
//       return light
//     } else {
//       return dark
//     }
//   })



function ChangeColor(theme) {
    if (theme=="light") {
         setTheme("dark")
         localStorage.setItem("theme","dark")

    } else {
       setTheme("light")
       localStorage.setItem("theme","light")

    }
}

 

  return (
<StateContext.Provider value={{theme,ChangeColor}}>
{children}
</StateContext.Provider>  )
}


export default AppContext