import React from 'react'
import {Routes, Route ,Navigate} from "react-router-dom";
 
import {Test } from './container'
import './App.scss';
import Home from "./Home";
import Swal from 'sweetalert2'

 
import Sorry from "./container/Otherpages/404 page/Sorry";
 import Designcard from "./container/Otherpages/designcard/Designcard";
import AppContext from './context/AppContext';


function App() {
   return (
      
      <AppContext>
         
      <div>

         <div className='app'>

            
            <Routes>
               <Route exact path="/" element={<Home />} />
            <Route path="/RamadhanFilter" element={<Test />} />
                <Route exact path={'/card'} element={<Designcard/>}/>

               {/* <Route exact path={'/work'} element={<Work/>}/> */}
                <Route path="/work" element={ <Navigate to="/#work" /> } />
                <Route path="/about" element={ <Navigate to="/#about" /> } />
                <Route path="/skills" element={ <Navigate to="/#skills" /> } />
                <Route path="/achievements" element={ <Navigate to="/#achievements" /> } />
                <Route path="/contact" element={ <Navigate to="/#contact" /> } />

           
               {/* <Route path="*" element={<Sorrynew />} />   */}
               <Route path="*" element={<Sorry />} />
            </Routes> 
         </div>
         <div>    
             <Routes>
            {/*  <Route path="*" element={<PageNotFound />} />*/}
         </Routes>
         
         </div>
      </div>

      </AppContext>
   );
}

export default App
