import React from 'react'
import { BsTwitter, BsInstagram, BsLinkedin, BsGithub } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa';
function SocialMedia() {
  return (
    <div className='app__social'>
     {/* {/* <a href='https://twitter.com/aymenghn' target="_blank"> <div><BsTwitter /> </div> </a> */}
     {/* <a href='https://www.facebook.com/ghniaaymen3/' target="_blank">  <div><FaFacebookF /> </div></a> */}
     <a href='https://link.aymenghnia.dev/linkedin' target="_blank"> <div><BsLinkedin /> </div></a>
     <a href='https://link.aymenghnia.dev/github' target="_blank"> <div><BsGithub /> </div></a>
     <a href='https://link.aymenghnia.dev/Instagram' target="_blank"> <div><BsInstagram /> </div></a> 

 


    </div>
  )
}

export default SocialMedia
