import React ,{useEffect}from 'react'
import { About, Footer, Header, Skills, Work, Achievements } from './container'
import { Navbar } from './components'
import Swal from 'sweetalert2'

import { useAppContext } from './context/AppContext'



 function Home() {

  const context= useAppContext();
  console.log(context.theme);
  useEffect(() => {
    const showPopup = () => {
      Swal.fire({
        title: 'Hey there!',
        text: 'I just couldn\'t resist making some improvements to my portfolio. Check out the shiny new version or continue enjoying this one. Your choice!',
        icon: 'info',
        showCancelButton:true,
         confirmButtonColor: '#808080',
        cancelButtonColor: '#d33',
        cancelButtonText:'Go to New Version',
        confirmButtonText: 'Continue Here'
      }).then((result) => {
        if (result.isConfirmed) {
          // User chose to stay in the current version
          Swal.fire(
            'Thanks for staying!',
            'Enjoy the current version of the portfolio.',
            'success'
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // User chose to go to the new version
          window.location.href = 'https://link.aymenghnia.dev/portfolio';
        }
      });
      
    };

    showPopup(); // Call the showPopup function to open the pop-up on component mount
  }, []); // Empty dependency array to ensure the effect runs only once
  return (
    <div>
  
<Navbar />

      <Header />
      <About />
   <Work />
   <Skills /> 
      <Achievements />

      <Footer />
   
    </div>
  )
}

export default Home
