import React from 'react';
import { NavigationDots, SocialMedia } from '../components';
import { useAppContext } from '../context/AppContext';

const AppWrap = (Component, idName, classNames,dark) => function HOC() {
  
  const context= useAppContext();
  return (
    <div id={idName} className={context.theme=="light"? `app__container ${classNames}`:`app__container ${dark}`}>
      <SocialMedia />
      <div className="app__wrapper app__flex">
        <Component />

        <div className="copyright">
          <p className="p-text"> Aymen Ghnia</p>
          <p className="p-text">All rights reserved</p>
        </div>
      </div>
      <NavigationDots active={idName} />
    </div>
  );
};

export default AppWrap;