import React, { useState , useRef } from 'react';
import emailjs from "@emailjs/browser";

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
 import './Footer.scss';
 import { useAppContext } from '../../context/AppContext';


const Footer = () => {


/*  */
const form = useRef();

const sendEmail = (e) => {
  e.preventDefault();
    setLoading(true);
  emailjs
    .sendForm(
      "service_utx4ejc",
      "template_dgasu2a",
      form.current,
      "Hqik_9K8_oS4znT81"
    )
    .then(
      (result) => {
        console.log(result.text);
        console.log("message sent");
        setIsFormSubmitted(true);
        setLoading(false);

      },
      (error) => {
        console.log(error.text);
      }
    );
};
/*  */









  const [formData, setFormData] = useState({ user_name: '', user_email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const context= useAppContext();
  const { user_name, user_email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = () => {
  

  //   const contact = {
  //     _type: 'contact',
  //     user_name: formData.user_name,
  //     user_email: formData.user_email,
  //     message: formData.message,
      
  //   };
  //   client.create(contact)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => console.log(err));
  // };

  return (
    <>
      <h2 className="head-text">Take a<span> coffee  </span>& <span>chat</span> with me</h2>
      <p className='Head-text'>DISCUSS A PROJECT OR JUST WANT TO SAY HI? MY INBOX IS OPEN FOR ALL.

 </p>
      <div className={context.theme=="light"?"app__footer-cards": "app__footer-cards dark"}>
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:contact@aymenghnia.dev" className="p-text">Contact@aymenghnia.dev</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+21651550022" className="p-text">+216 51 550 022</a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
           <form onSubmit={sendEmail} className="app__footer-form app__flex" ref={form}  >
         
          <div className="app__flex">
 
            <input className="p-text" type="text" placeholder="Your Name" name="user_name"  required value={user_name} onChange={handleChangeInput}  />
          </div>
          <div className="app__flex">
            <input className="p-text" type="email" placeholder="Your Email" name="user_email" required value={user_email} onChange={handleChangeInput} />
         

          </div>
          <div>
            <textarea
            required
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type="submit" value="Send" className="p-text" >{!loading ? 'Send Message' : 'Sending...'}</button>
      
      
          
 
   </form>


   
    
        </div>
    
   
    ) : (
        <div>
          <h3 className="head-text">
            Thank you for getting in <span>touch!</span>
          </h3>
        </div>
      )}
      
    </>
  );
};

// export default AppWrap(
//   MotionWrap(Footer, 'app__footer'),
//   'contact',
//   'app__whitebg',
// );

 

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),'contact','app__whitebg','app__footerdark',
 
);