import React from 'react'
import './Designcard.scss'
import { motion } from 'framer-motion'
import { Link } from "react-router-dom"
import ReactTooltip from 'react-tooltip';


const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}


function Designcard() {
  return (
    <div className="card-main">
      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        transition={{ duration: 1, ease: 'easeIn' }}>
        <div className="hero-section">
          <div className="card-grid">

            {/* static - i will update it with sanity ! */}
            <a className="card" href="#">

              <div
                className="card__background"
                style={{
                  backgroundImage:
                    "url(https://i.ibb.co/0KfFxYK/Artboard-1-auto-x2.jpg)"
                }}
              />
              <div className="card__content"></div>
            </a>
            <a className="card" href="#">
              <div
                className="card__background"
                style={{
                  backgroundImage: "url(https://i.ibb.co/qjLwqL4/Artboard-2.jpg)"
                }}
              />
              <div className="card__content"></div>
            </a>
            <a className="card" href="#">
              <div
                className="card__background"
                style={{
                  backgroundImage:
                    "url(https://i.ibb.co/yQn8PfF/Artboard-1-auto-x2-2.jpg)"
                }}
              />
              <div className="card__content"></div>
            </a>
            <a className="card" href="#">
              <div
                className="card__background"
                style={{
                  backgroundImage: "url(https://i.ibb.co/vP6Sxfp/Artboard-2.jpg)"
                }}
              />
              <div className="card__content"></div>
            </a>
            <div>



              <p data-tip data-for='happyFace' className="text-center" style={{ color: "white" }}>
                Copyright 2022 | Made With love by <Link to="/" style={{ color: "white", textDecoration: "none" }}> AYMEN GHNIA </Link>

              </p> 
               <ReactTooltip className="skills-tooltip" id='happyFace' effect="solid"
                arrowColor="#fff" >
                Made With love by AYMEN GHNIA </ReactTooltip>
            </div>
          </div>



        </div>
      </motion.div>
    </div>

  )
}

export default Designcard