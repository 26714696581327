import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReactTooltip from 'react-tooltip';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Testimonial.scss';
import { useAppContext } from '../../context/AppContext';

const Achievements = () => {
  // const [currentIndex, setCurrentIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([]);
  const [brands, setBrands] = useState([]);
  const [certificats, setcertificats] = useState([]);
  const context= useAppContext();


  // const handleClick = (index) => {
  //   setCurrentIndex(index);
  // };

  useEffect(() => {
    const query = '*[_type == "testimonials"]';
    const brandsQuery = '*[_type == "brands"]';
    const certifuquery = '*[_type == "certificats"]';

    client.fetch(query).then((data) => {
      setTestimonials(data);
    });

    
  client.fetch(certifuquery).then((data) => {
    setcertificats(data);
  });


    client.fetch(brandsQuery).then((data) => {
      setBrands(data);
    });
  }, []);

 

  return (
    <>
      {testimonials.length && (
        <>
        <h2 className="head-text" style={{ marginTop: -1 ,marginBottom: 60 }}>
       <span> Achievements 🏆 </span>
      </h2>
      
          <div className={context.theme=="light"?"app__testimonial-item app__flex": "app__testimonial-item app__flex dark"}>
          
                <div>
                 

                {/*  Education section */}

                <motion.div className='app__skills-exp'>
                  {certificats.map((certificat) => (
                    <motion.div
                      className="app__skills-exp-item"
                      key={certificat.year}
                    >
                      <div className="app__skills-exp-year">
                        <p className="bold-text">{certificat.year}</p>
                      </div>

                      <motion.div className="app__skills-exp-works">
                        {certificat.works.map((certif => (
                          <>
                            <motion.div
                              whileInView={{ opacity: [0, 1] }}
                              transition={{ duration: 0.5 }}
                              className="app__skills-exp-work"
                              data-tip
                              data-for={certif.name}
                              key={certif.name}
                              style={{ marginTop: 1 ,marginBottom: 1  }}
                            >
                              <h4 className='bold-text'>🥇 {certif.name}</h4>
                              <p className='p-text'>{certif.company}</p>
                            </motion.div>
                            <ReactTooltip followCursor
                              id={certif.name}
                               effect="solid"
                              arrowColor="#fff"
                              className="skills-tooltip"
                            >
                              {certif.desc}
                            </ReactTooltip>
                          </>
                        )))}

                      </motion.div>

                    </motion.div>




                  ))}

                </motion.div>
                {/*  certeficat section */}

             </div>
          </div>

        {/*   <div className="app__testimonial-btns app__flex">
            <div className="app__flex" onClick={() => handleClick(currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1)}>
              <HiChevronLeft />
            </div>

            <div className="app__flex" onClick={() => handleClick(currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1)}>
              <HiChevronRight />
            </div>
          </div>*/}
        </>
      )}

     
      <div className="app__testimonial-brands app__flex">
        {brands.map((brand) => (

          <motion.div data-tip data-for={brand.desc}
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: 'tween' }}
            key={brand._id}
          >
            <a href={brand.codeLink} target="_blank" >           
             <img src={urlFor(brand.imgUrl)} alt={brand.name} />
             </a>
                <ReactTooltip followCursor className="skills-tooltip" id={brand.desc} effect="solid"
                 arrowColor="#313bac" >{brand.desc} </ReactTooltip>    
          </motion.div>

        ))}
      </div>
    </>
  );
};

// export default AppWrap(
//   MotionWrap(Achievements, 'app__testimonial'),
//   'achievements',  
//   'app__primarybg',
// );

export default AppWrap(
  MotionWrap(Achievements, 'app__testimonial'),'achievements','app__primarybg','app__testimonialdark',
 
);



