import React, { useState, useEffect } from 'react'
import { urlFor, client } from '../../client'
import { AiFillEye, AiFillGithub } from 'react-icons/ai'
import { motion } from 'framer-motion'
import { AppWrap, MotionWrap } from '../../wrapper';
import ReactTooltip from 'react-tooltip';
import './Skills.scss';
import { useAppContext } from '../../context/AppContext';

function Skills() {
  const [experiences, setExperiences] = useState([]);
  const context= useAppContext();
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';
    const skillsQuery = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      setExperiences(data);
    });

    client.fetch(skillsQuery).then((data) => {
      setSkills(data);
    });
  }, []);


  return (
    <>
       <h3 className="head-text">
        <span>Skills </span>& <span>Experiences</span>
      </h3>
      <div 
      className={context.theme=="light"?"app__skills-container": "app__skills-container dark"}>
        <motion.div className="app__skills-list">
          {skills.map((skill) => (
            <motion.div
             transition={{ duration: 0.5}}
               whileInView={{ opacity: [0, 1] }}
              className="app__skills-item app__flex"
              key={skill.name}
            >
              <div
                className="app__flex"
                style={{ backgroundColor: skill.bgColor }}
              >
                <img src={urlFor(skill.icon)} alt={skill.name} />
              </div>
              <p className="p-text">{skill.name}</p>
            </motion.div>
          ))}
        </motion.div>

        <motion.div className='app__skills-exp'>
          {experiences.map((experience) => (
            <motion.div
              className="app__skills-exp-item"
              key={experience.year}
            >
              <div className="app__skills-exp-year">
                <p className="bold-text">{experience.year}</p>
              </div>
              <motion.div className="app__skills-exp-works">
                {experience.works.map((work => (
                  <>
                    <motion.div
                      whileInView={{ opacity: [0, 1] }}
                      transition={{ duration: 0.5 }}
                      className="app__skills-exp-work"
                      data-tip
                      data-for={work.name}
                      key={work.name}
                    >
                      <h4 className='bold-text'>{work.name}</h4>
                      <p className='p-text'>{work.company}</p>
                    </motion.div>
                    <ReactTooltip
                      id={work.name}
                       effect="solid"
                      arrowColor="#fff"
                      className="skills-tooltip"
                    >
                      {work.desc}
                    </ReactTooltip>
                  </>
                )))}

              </motion.div>

            </motion.div>




          ))}

        </motion.div>

      </div>
    </>
  )
}
// export default AppWrap(
//   MotionWrap(Skills, 'app__skills'),
//   'skills',
//   'app__whitebg',
// );

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),'skills','app__whitebg','app_skillsdark',
 
);