import React, { useEffect, useState, useRef } from "react";
import './Navbar.scss'
import { images } from '../../constants'
import { HiMenuAlt4, HiX } from 'react-icons/hi'
import { motion } from 'framer-motion'
import { Link } from "react-scroll";
import { useOnClickOutside } from "../../hooks";
import { useAppContext } from "../../context/AppContext";
import ReactTooltip from 'react-tooltip';


const Navbar = (active) => {

  const context = useAppContext();

  //close menu when i click out side the menu by Saber <3 :* 
  const wrapperRef = useRef();
  useOnClickOutside(wrapperRef, () => setToggle(false));



  const [toggle, setToggle] = useState(false)
  //  const [activ, setactiv] = useState("home")
  // const [activeNav , setactiveNav] = useState('Home')


  const [y, setY] = useState(window.scrollY);
  // const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);



  return (

    

    <nav className={context.theme === "light" ? "app__navbar" : "app__navbar dark"}>


      <div className="app__navbar-logo">
        <Link className="pointer flexNullCenter" to="home" smooth={true}>

          {context.theme === "light" ? (
            // <img className="logo" src={images.logoblack} alt="logo" />
            <img  draggable="false" className="logo" src={images.darklogo} alt="logo" />
          ) : (
            <img draggable="false" className="logo" src={images.whitelogo} alt="logo" />
          )}
        </Link>


      </div>

      <ul className="app__navbar-links">
        {['home', 'about', 'work', 'skills', 'achievements', 'contact'].map((item) => (
          <li spy={true} className="app__flex p-text" key={`link-${item}`} >
            <div />
            {/*<p>{activ === item? "it's here":""}</p>*/}
            {/*   <a  className={`app__flex p-text ${activ === item ? 'item-active' : ''}`} onClick={() => setactiv(item)} key={`link-${item}`} href={`#${item}`}>{item}</a>
         */} {/* onClick={() => setactiv(item)} */}
            <Link
              activeClass="item-active"
              className={` pointer flexNullCenter app__flex p-text `}
              to={item} smooth={true}
              spy={true}
              key={`link-${item}`} href={`#${item}`}>{item}
            </Link>

          </li>
        ))}

      </ul>



      {/*     
  <a style={{ marginRight: 40, marginLeft: 1 }} className='cv' href={images.cv} download="AymenGhnia_Cv_Fr.pdf"> 
  <div class="button-container-2">
      <span class="mas">DOWNLOAD CV</span>
    <button id='work' type="button" name="Hover">DOWNLOAD CV</button>
  </div>
      
      </a>*/}

      {/* <div  className="toggle-theme-wrapper">
      <span>☀️</span>
      <label className="toggle-theme" htmlFor="checkbox">
        <input onClick={()=>context.ChangeColor(context.theme)}
          type="checkbox"
          id="checkbox"
        />
        <div  className="slider round"></div>
      </label>
      <span>🌒</span>
    </div> */}

      {/* <a style={{ marginRight: 40, marginLeft: 1 }} className='cv' href={images.cv} download="AymenGhnia_Cv_Fr.pdf">
    <button class="button-38-nav"  >Download CV
      </button> </a> */}


      <div onClick={() => context.ChangeColor(context.theme)} className="toggle-theme-wrapper">
        {/* {context.theme=="light" ? (  <span>☀️</span>):(<span>🌒</span>)}
      */}
                
        {context.theme === "light" ? (

          <><p data-tip data-for='moon'>
            <img alt='Dark mode' src={images.moon}></img>
          </p><ReactTooltip className="skills-tooltip-light" id='moon' effect="solid"
            arrowColor="black">
              Enable DarkMode !</ReactTooltip></>
        ) : (
          <>
          <p data-tip data-for='sun'>
        <img  alt ='light mode'src={images.sun}></img>
        </p>
        <ReactTooltip className="skills-tooltip-dark" id='sun' effect="solid"
            arrowColor="#fff">
              Enable LightMode !</ReactTooltip>
        </>
        )}

        

      </div>










      <div className="app__navbar-menu">


        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div ref={wrapperRef} whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeInOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>

              {['home', 'about', 'work', 'skills','achievements', 'contact'].map((item) => (
                <li key={item}>

                  <a href={`#${item}`} onClick={() => setToggle(false)} > {item}</a>

                </li>
              ))}
              <a className={context.theme === "light" ? "yosser" : "yosser dark"}  style={{ paddingLeft: 15, paddingBottom: 15 }} onClick={() => context.ChangeColor(context.theme) } >
                {context.theme === "light" ?
                 
                 (



                    <span  onClick={() => setToggle(false)} >Enable Drak Mode 🌒</span>
                  ) : (
                    <span  onClick={() => setToggle(false)} >Enable Ligh Mode ☀️</span>


                    
                  )}
              </a>
              {/* <a className='cv' href={images.cv} download="AymenGhnia_Cv_Fr.pdf"><button class="button-38"  >Download CV</button> </a> */}
              <a className='cv' href="https://link.aymenghnia.dev/cv_fr" download="AymenGhnia_Cv_Fr.pdf"><button class="button-38"  >Download CV</button> </a>

            </ul>
          </motion.div>
        )}

      </div>

    </nav>

  )
}

export default Navbar
