//import email from '../assets/email.png';
//import mobile from '../assets/mobile.png';
//import api from '../assets/api.png';
//import cpp from '../assets/cpp.png';
//import css from '../assets/css.png';
//import figma from '../assets/figma.png';
//import flutter from '../assets/flutter.png';
//import git from '../assets/git.png';
//import graphql from '../assets/graphql.png';
//import html from '../assets/html.png';
//import javascript from '../assets/javascript.png';
//import mu5 from '../assets/mu5.png';
//import node from '../assets/node.png';
//import python from '../assets/python.png';
//import react from '../assets/react.png';
//import redux from '../assets/redux.png';
//import sass from '../assets/sass.png';
//import typescript from '../assets/typescript.png';
//import vue from '../assets/vue.png';  
//import about01 from '../assets/about01.png';
//import about02 from '../assets/about02.png';
//import about03 from '../assets/about03.png';
//import about04 from '../assets/about04.png';
//import profile from '../assets/profile.png';
//import aymen from '../assets/aymen.png';
import circle from '../assets/circle.svg';
import circledark from '../assets/circledark.svg';

import sun from '../assets/sun.svg';
import moon from '../assets/moon.svg';

import logoblack from '../assets/logoblack.svg';
import logowhite from '../assets/logowhite.svg';


//import circle from '../assets/circle.png';
//import logo from '../assets/logo.png';
//import adidas from '../assets/adidas.png';
//import amazon from '../assets/amazon.png';
//import asus from '../assets/asus.png';
//import bolt from '../assets/bolt.png';
//import nb from '../assets/nb.png';
//import skype from '../assets/skype.png';
//import spotify from '../assets/spotify.png';
//import me from '../assets/me.png';
//import meagain from '../assets/meagain.png';
//import mewhite from '../assets/mewhite.png';

const testa = "https://i.ibb.co/bJzkCbP/c7.png";
const cv = "https://link.aymenghnia.dev/cv_fr";
//const me = "https://res.cloudinary.com/dorrhcigy/image/upload/v1666135999/me_ihpgs3.png";
const me = "https://res.cloudinary.com/dorrhcigy/image/upload/v1666729169/Me/file%20fix%20size/this_me_jsy1pe.png";
const aymen = "https://res.cloudinary.com/dorrhcigy/image/upload/v1666135997/aymen_rhgzn8.png";
const logo = "https://res.cloudinary.com/dorrhcigy/image/upload/v1666733633/Me/file%20fix%20size/logo%20-%20s/logo_aymen_llugik.png";
const email ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666733722/Me/file%20fix%20size/logo%20-%20s/Artboard_2_g7nbuu.png"
// const typescript ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666135998/typescript_jrpaad.png";
//const react ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666142437/Up/react_ibrvas.png";
const react ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666730293/Me/file%20fix%20size/Artboard_2_kzjad5.png";
// const node ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666730293/Me/file%20fix%20size/Artboard_3_ttckig.png";

const node ="https://dev.aymenghnia.dev/img/flutter.png";
const figma ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666730292/Me/file%20fix%20size/Artboard_1_qnqknf.png";

//const figma ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666135998/figma_ko7jyy.png";
// const python ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666135998/python_jyaoa2.png";
// const javascript ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666135999/javascript_zn4tp1.png";
//const node ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666135997/node_wbvjrt.png";
// const vue ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666135999/vue_remlfv.png";
// const html ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666135998/html_codfjn.png";
// const git ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666135998/git_hnmxys.png";
// const css ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666135997/css_eozjrp.png";
const mobile ="https://res.cloudinary.com/dorrhcigy/image/upload/v1666733722/Me/file%20fix%20size/logo%20-%20s/Artboard_1_dujjjg.png";
const aboutaymen = "https://res.cloudinary.com/dorrhcigy/image/upload/v1666730614/Me/file%20fix%20size/aymeen_iv1xsp.png";
//const logowhite ="https://res.cloudinary.com/dorrhcigy/image/upload/v1667438539/Artboard_1_joyvoh.svg";
//const logoblack="https://res.cloudinary.com/dorrhcigy/image/upload/v1667439933/bgtext-edit_agkzwx.svg";
// const logowhite="https://res.cloudinary.com/dorrhcigy/image/upload/v1667444622/logowhite_ezkhi8.svg";
// const logoblack="https://res.cloudinary.com/dorrhcigy/image/upload/v1667444622/logoblack_j0xgbl.svg";
const darklogo ="https://res.cloudinary.com/dorrhcigy/image/upload/v1667446294/dark_rfdjmx.png";
const whitelogo ="https://res.cloudinary.com/dorrhcigy/image/upload/v1667446294/white_lucuxq.png";
export default {
  cv,
  darklogo,
  whitelogo,
  logoblack,
  logowhite,
  aboutaymen,
  me,
  testa,
  email,
  mobile,
  // css,
  figma,
  // git,
  // html,
  // javascript,
  node,
  // python,
  react,
  // typescript,
  circle,
  circledark,
  sun,
  moon,
  logo,
  aymen,
 };