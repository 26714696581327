import { Link } from "react-router-dom"
import './Sorry.scss';
import './css/font-awesome.min.css';
import { motion } from 'framer-motion'

const PageNotFound = () => {
  return (
	
     <div id="notfound">
		<div  className="notfound">
			
		<motion.div
	whileInView={{ scale: [0, 1] }}
	transition={{ duration: 1, ease: 'easeIn' }}
      
      >
			
			
			<div  className="notfound-bg">
				<div></div>
				<div></div>
				<div></div>
			</div>
			<h1>oops!</h1>
			<h2>Page Not Found</h2>
            <Link to="/">go back</Link>

			
			<div  className="notfound-social">
				<a href="https://www.facebook.com/ghniaaymen3/" target="_blank"><i  className="fa fa-facebook"></i></a>
				<a href="https://twitter.com/aymenghn" target="_blank"><i  className="fa fa-twitter"></i></a>
				<a href="https://www.instagram.com/aymen_ghnia/" target="_blank"><i  className="fa fa-instagram"></i></a>
			</div>
	
	
			</motion.div>

	
		</div>
	</div>
  );
}
 
export default PageNotFound;
 