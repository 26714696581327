import React from 'react'
import { Link } from "react-scroll";

function NavigationDots({ active }) {
    return (
        <div className='app__navigation'> 
         
         
         
            {['home', 'about' , 'work', 'skills','achievements','contact'].map((item,index) => (
 
 
                     <Link  
                     activeClass="item-active"  
                     className='app__navigation-dot'
                     to={item} smooth={true}  
                     
                     spy={true}   
                     key={item + index} href={`#${item}`}
                     style={active == item ? { backgroundColor : '#313BAC'} : { }} > 
                     </Link>

             ))}
        </div>

        

    )
}

export default NavigationDots
