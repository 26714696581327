import React, { useState, Component } from "react";
import "./Header.scss";
import { AppWrap } from "../../wrapper";
import { motion } from "framer-motion";
import { images } from "../../constants";
// import Typical from 'react-typical'
import { useAppContext } from "../../context/AppContext";
import { TypeAnimation } from "react-type-animation";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};
function Header() {
  const [dark, setdark] = useState(false);
  const toggleSwitch = () => setdark(!dark);
  const [theme, setTheme] = useState("light");

  const context = useAppContext();

  return (
    <div id="home" className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 2 }}
        className="app__header-info"
      >
        <div draggable="false" className="app__header-badge">
          <div
            style={{ marginRight: 22, marginTop: -5 }}
            className="badge-cmp app__flex"
          >
            <span className="hand">👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text"> Hello, I am</p>
              <h1 className="head-textaymenGhnia">Aymen Ghnia</h1>
            </div>
          </div>

          {context.theme == "light" ? (
            <div className="tag-cmp app__flex">
              <p className="p-text">
                {/* <Typical
        steps={['Web Developer', 2000, 'Freelancer', 2000, 'Designer', 2000]}
        loop={Infinity}
        /> */}
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={[
                    "WEB DEVELOPER",
                    1000,
                    "FREELANCER",
                    1000,
                    "DESIGNER",
                    1000,
                  ]}
                  speed={50} // Custom Speed from 1-99 - Default Speed: 40
                  style={{ fontSize: "1em" }}
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </p>
            </div>
          ) : (
            <p className="p-text">
              <div className="tag-cmpbox app__flex">
                {/* <Typical
 steps={['Web Developer', 2000, 'Freelancer', 2000, 'Designer', 2000]}
 loop={Infinity}
 wrapper="p"/> */}
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={[
                    "WEB DEVELOPER",
                    1000,
                    "FREELANCER",
                    1000,
                    "DESIGNER",
                    1000,
                  ]}
                  speed={50} // Custom Speed from 1-99 - Default Speed: 40
                  style={{ fontSize: "1em" }}
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </div>
            </p>
          )}

          <div className="tag-cmpme app__flex">
            {context.theme == "light" ? (
              <img
                src="https://res.cloudinary.com/dorrhcigy/image/upload/v1667439454/thisme1_wklcyi.png"
                alt="this me phone"
              />
            ) : (
              <img
                src="https://res.cloudinary.com/dorrhcigy/image/upload/v1667439454/thisme2_curvjm.png"
                alt="this me phone"
              />
            )}
          </div>
        </div>
      </motion.div>

      {/* whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 2, delayChildren: 0.5 }}*/}
      <motion.div className="app__header-img">
        {/**/}

        {context.theme == "light" ? (
          <motion.img
            // whileInView={{ scale: [0, 1] }}
            //   transition={{ duration: 1, ease: 'easeIn' }}
            whileInView={{ y: [100, 0] }}
            transition={{ duration: 1.5 }}
            src={images.aymen}
            alt="Aymen Ghnia photo"
            //src='https://res.cloudinary.com/dorrhcigy/image/upload/v1666135997/aymen_rhgzn8.png' srcset='https://res.cloudinary.com/dorrhcigy/image/upload/v1666726871/Me/Artboard_3_lw9lm4.png 400w, https://res.cloudinary.com/dorrhcigy/image/upload/v1666726872/Me/Artboard_2_lvqylx.png 800w, https://res.cloudinary.com/dorrhcigy/image/upload/v1666135997/aymen_rhgzn8.png 1050w' alt='Aymen Ghnia photo'
            className="profile_bg"
          />
        ) : (
          <motion.img
            // whileInView={{ scale: [0, 1] }}
            //   transition={{ duration: 1, ease: 'easeIn' }}
            whileInView={{ y: [100, 0] }}
            transition={{ duration: 1.5 }}
            src={images.aymen}
            alt="Aymen Ghnia photo"
            //src='https://res.cloudinary.com/dorrhcigy/image/upload/v1666135997/aymen_rhgzn8.png' srcset='https://res.cloudinary.com/dorrhcigy/image/upload/v1666726871/Me/Artboard_3_lw9lm4.png 400w, https://res.cloudinary.com/dorrhcigy/image/upload/v1666726872/Me/Artboard_2_lvqylx.png 800w, https://res.cloudinary.com/dorrhcigy/image/upload/v1666135997/aymen_rhgzn8.png 1050w' alt='Aymen Ghnia photo'
            className="profile_bg"
          />
        )}

        {/* whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1, ease: 'easeInOut' }}*/}
        {/* <img  src={images.aymen} alt="profile_bg" /> */}
        {context.theme == "light" ? (
          <motion.img
            src={images.circle}
            alt="profile_circle"
            className="overlay_circle"
          />
        ) : (
          <motion.img
            src={images.circledark}
            alt="profile_circle"
            className="overlay_circle"
          />
        )}
      </motion.div>
      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="this_is_me"
      >
        {context.theme == "light" ? (
          <motion.img
            whileInView={{ scale: [0, 1] }}
            transition={{ duration: 1, ease: "easeIn", delayChildren: 0.5 }}
            //src={images.me}
            //  src='https://res.cloudinary.com/dorrhcigy/image/upload/v1666729169/Me/file%20fix%20size/this_me_jsy1pe.png'
            src="https://res.cloudinary.com/dorrhcigy/image/upload/v1667439454/thisme1_wklcyi.png"
            alt="me_arow"
            className="me_arow"
          />
        ) : (
          <motion.img
            whileInView={{ scale: [0, 1] }}
            transition={{ duration: 1, ease: "easeIn", delayChildren: 0.5 }}
            //src={images.me}
            //  src='https://res.cloudinary.com/dorrhcigy/image/upload/v1666729169/Me/file%20fix%20size/this_me_jsy1pe.png'
            src="https://res.cloudinary.com/dorrhcigy/image/upload/v1667439454/thisme2_curvjm.png"
            alt="me_arow"
            className="me_arow"
          />
        )}
      </motion.div>

      <motion.div
        variant={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circle"
      >
        {[images.figma, images.react, images.node].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="cirlce" />
          </div>
        ))}
      </motion.div>
    </div>
  );
}

export default AppWrap(Header, "home", "home", "homedark");
